exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-22-index-tsx": () => import("./../../../src/pages/admin22/index.tsx" /* webpackChunkName: "component---src-pages-admin-22-index-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-booking-tsx": () => import("./../../../src/pages/en/booking.tsx" /* webpackChunkName: "component---src-pages-en-booking-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-gallery-tsx": () => import("./../../../src/pages/en/gallery.tsx" /* webpackChunkName: "component---src-pages-en-gallery-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-prices-tsx": () => import("./../../../src/pages/en/prices.tsx" /* webpackChunkName: "component---src-pages-en-prices-tsx" */),
  "component---src-pages-en-rules-tsx": () => import("./../../../src/pages/en/rules.tsx" /* webpackChunkName: "component---src-pages-en-rules-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prices-tsx": () => import("./../../../src/pages/prices.tsx" /* webpackChunkName: "component---src-pages-prices-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */)
}

